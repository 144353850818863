import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PressPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="intro">
      <div className="intro-container">
        <h2>Press</h2>
        <p>Since 2016, Preston has appeared in <strong>mainstream</strong>, <strong>LGBTQ+</strong>, and <strong>kink</strong> media in <strong>German</strong>, <strong>Brazilian Portuguese</strong>, and <strong>English</strong>.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <ul className="content-list">
          <li>
            <h3><a href="https://www.wearlatex.com/travelling-in-latex-tight-connection/" rel="noreferrer" target="_blank">Traveling in latex: Tight Connection</a></h3>
            <p><em>WearLatex.com</em></p>
            <p><small>July 4, 2023</small></p>
          </li>
          <li>
            <h3><a href="https://www.vice.com/en/article/88xqdk/rubberists-are-starting-their-own-revolution" rel="noreferrer" target="_blank">"Fuck the rules": Rubberists are starting their own revolution</a></h3>
            <p><em>Vice</em></p>
            <p><small>May 25, 2023</small></p>
          </li>
          <li>
            <h3><a href="https://alphatribe.com/mir20-the-first-international-title-holder-of-asian-decent/" rel="noreferrer" target="_blank">MIR20, the first Asian international fetish titleholder</a></h3>
            <p><em>Alphatribe Magazine</em></p>
            <p><small>January 31, 2021</small></p>
          </li>
          <li>
            <h3><a href="https://www.wattsthesafeword.com/podcast/preston" rel="noreferrer" target="_blank">Race and representation in kink</a></h3>
            <p><em>Watts the Safeword Podcast</em></p>
            <p><small>November 27, 2020</small></p>
          </li>
          <li>
            <h3><a href="https://blubr.org/preston/" rel="noreferrer" target="_blank">Preston "wexx", Mr. Rubber Internacional conta sua história e sobre o Mr. Rubber Brasil ao BLUBR</a></h3>
            <p><em>BLUBR</em></p>
            <p><small>June 13, 2019</small></p>
          </li>
          <li>
            <h3><a href="https://www.youtube.com/watch?v=fvwebjls4ag" rel="noreferrer" target="_blank">Você tem o fetiche por látex?</a></h3>
            <p><em>Rádio Agita Planeta</em></p>
            <p><small>May 23, 2019</small></p>
          </li>
          <li>
            <h3><a href="https://box-magazin.com/januar-2018/" rel="noreferrer" target="_blank">Im Interview mit Preston "wexx"</a></h3>
            <p><em>BOX Magazin</em></p>
            <p><small>January 2018</small></p>
          </li>
          <li>
            <h3><a href="https://igay.ig.com.br/colunas/masmorra-do-fausto/2017-08-11/fetiche-latex-rubber.html" rel="noreferrer" target="_blank">Fetiche por látex: saiba como funciona a cultura rubber com um especialista</a></h3>
            <p><em>iGay no iG</em></p>
            <p><small>August 11, 2017</small></p>
          </li>
          <li>
            <h3><a href="https://joy.org.au/madwednesdays/2017/07/07/rubbermen-visit-things-get-slick-studio/" rel="noreferrer" target="_blank">Rubbermen visit and things get slick in the studio</a></h3>
            <p><em>JOY 94.9 Melbourne</em></p>
            <p><small>July 7, 2017</small></p>
          </li>
          <li>
            <h3><a href="https://www.mixcloud.com/nosafewordpodcast/episode-207-lawd-have-mir-cy/" rel="noreferrer" target="_blank">Lawd have MIR-cy</a></h3>
            <p><em>NoSafeWord Podcast</em></p>
            <p><small>March 14, 2017</small></p>
          </li>
          <li>
            <h3><a href="https://www.youtube.com/watch?v=Z5SznL9gsC0" rel="noreferrer" target="_blank">Miss America meets kink, with Mr. International Rubber</a></h3>
            <p><em>Watts the Safeword</em></p>
            <p><small>February 17, 2017</small></p>
          </li>
          <li>
            <h3><a href="https://www.vice.com/en/article/4xxvkp/the-fetish-community-is-ready-to-whip-president-trump" rel="noreferrer" target="_blank">The fetish community is ready to whip President Trump</a></h3>
            <p><em>Vice.com</em></p>
            <p><small>January 20, 2017</small></p>
          </li>
          <li>
            <h3><a href="https://greatlakesden.com/preston-wins-mir-20/" rel="noreferrer" target="_blank">Preston "wexx" So wins MIR 20</a></h3>
            <p><em>Great Lakes Den</em></p>
            <p><small>November 8, 2016</small></p>
          </li>
        </ul>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default PressPage
